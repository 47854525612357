const nonServiceablePostcodePatterns = [
  /^BT/,
  /^EIRE/,
  /^GY/,
  /^HS[1-9]/,
  /^IM/,
  /^IRE/,
  /^IREL/,
  /^IV4[0-9]/,
  /^IV5[0-1]/,
  /^IV5[5-6]/,
  /^JE/,
  /^KA27/,
  /^KA28/,
  /^KW1[5-7]/,
  /^PA20/,
  /^PA4[2-9]/,
  /^PA6[0-9]/,
  /^PA7[0-6]/,
  /^PA8[0-8]/,
  /^TR2[1-5]/,
  /^ZE[1-3]/,
  /^EX[1-9]/,
  /^EX1[0-9]/,
  /^EX2[0-4]/,
  /^EX3[1-9]/,
  /^PL[1-9]/,
  /^PL1[0-9]/,
  /^PL2[0-9]/,
  /^PL3[0-5]/,
  /^PL95/,
  /^TQ[1-9]/,
  /^TQ1[0-4]/,
  /^TR[1-9]/,
  /^TR1[0-9]/,
  /^TR2[6-7]/,
  /^TR20/,
  /^TR93/,
  /^AB[1-5]/,
  /^AB1[0-6]/,
  /^AB2[1-5]/,
  /^AB3[0-6]/,
  /^AB3[8-9]/,
  /^AB37/,
  /^AB4[1-5]/,
  /^AB5[1-8]/,
  /^AB9/,
  /^IV[1-9]/,
  /^IV1[0-9]/,
  /^IV2[0-8]/,
  /^IV3[0-6]/,
  /^IV40/,
  /^IV5[2-4]/,
  /^IV63/,
  /^KW[1-3]{1} [A-Za-z]/,
  /^KW[5-9]/,
  /^KW1[0-4]/,
  /^KW1[8-9]/,
  /^KW2[0-9]/,
  /^KW3[0-9]/,
  /^PA3[8-9]/,
  /^PA36/,
  /^PA40/,
  /^PH[49]/,
  /^PH[50]/,
  /^PH1[7-9]/,
  /^PH2[0-6]/,
  /^PH3[0-6]/,
  /^PH3[7-9]/,
  /^PH4[0-4]/,
  /^PO3[0-9]/,
  /^PO4[0-1]/,
  /^DD/,
  /^DG/,
  /^EH/,
  /^FK/,
  /^G1-9/,
  /^G1[0-9]/,
  /^G2[0-9]/,
  /^G3[0-9]/,
  /^G4[0-9]/,
  /^G5[0-9]/,
  /^G6[0-9]/,
  /^G7[0-9]/,
  /^G8[0-9]/,
  /^G90/,
  /^KA[0-9]/,
  /^KA1[0-9]/,
  /^KA2[0-7]/,
  /^KA29/,
  /^KA3[0-9]/,
  /^KA4[0-9]/,
  /^KA5[0-9]/,
  /^KA6[0-9]/,
  /^KA7[0-9]/,
  /^KA8[0-9]/,
  /^KA9[0-9]/,
  /^KY/,
  /^ML/,
  /^PA[1-9]/,
  /^PA1[0-9]/,
  /^PA2[1-9]/,
  /^PA3[0-5]/,
  /^PA37/,
  /^PH[1-9]/,
  /^PH1[0-6]/,
  /^TD/,
];

export default function validateServiceablePostcode(postcode: string) {
  if (!postcode || typeof postcode !== 'string' || postcode.length <= 0) {
    return true;
  }

  return nonServiceablePostcodePatterns.some((pattern) => {
    return pattern.test(postcode);
  });
}
