import { useState, useContext } from 'react';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { sdk } from '~/sdk';

interface LoqateCaptureResponse {
  Description: string;
  Highlight: string;
  Id: string;
  Text: string;
  Type: string;
}

export interface AddressDetailResponse {
  Company?: string;
  BuildingNumber?: string;
  SubBuilding?: string;
  BuildingName?: string;
  Street?: string;
  District?: string;
  City?: string;
  ProvinceName?: string;
  PostalCode?: string;
  CountryName?: string;
  CountryIso2?: string;
  CustomItem?: string;
}

interface UseAddressHookResponse {
  suggestedAddresses: LoqateCaptureResponse[];
  address: AddressDetailResponse;
  error: string | null;
  getSuggestedAddresses: (postcode: string, container?: string) => Promise<void>;
  getAddress: (id: string) => Promise<void>;
  setAddress: (type: keyof AddressDetailResponse, value: string) => void;
}

export function useAddressHook(): UseAddressHookResponse {
  const { executeWithProgress } = useContext(ProgressIndicatorContext);
  const [suggestedAddresses, setSuggestedAddresses] = useState<LoqateCaptureResponse[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [address, setAddress] = useState<AddressDetailResponse>({
    Company: '',
    BuildingNumber: '',
    SubBuilding: '',
    BuildingName: '',
    Street: '',
    District: '',
    City: '',
    ProvinceName: '',
    PostalCode: '',
    CountryName: '',
    CountryIso2: '',
    CustomItem: '',
  });

  const handleAddressFieldInput = (type: keyof AddressDetailResponse, value: string) => {
    setAddress((prevAddress) => {
      const updatedAddress = { ...prevAddress, [type]: value };
      updatedAddress.CustomItem =
        `${updatedAddress.BuildingNumber} ${updatedAddress.Company} ${updatedAddress.BuildingName}`.trim();
      return updatedAddress;
    });
  };

  const getSuggestedAddresses = async (postcode: string, container?: string): Promise<void> => {
    if (postcode === '') {
      setSuggestedAddresses([]);
      return;
    }

    const payload: { postcode: string; container?: string } = { postcode, container };

    try {
      const res = await executeWithProgress(() =>
        sdk.callAction({
          actionName: 'furniturechoice-loqate/getAddressList',
          payload,
        }),
      );

      if (res.isError) {
        throw res.error;
      }

      const addressesData = res.data as LoqateCaptureResponse[] | undefined;
      setSuggestedAddresses(addressesData || []);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
    }
  };

  const getAddress = async (id: string): Promise<void> => {
    const payload: { id: string } = { id };

    try {
      const res = await executeWithProgress(() =>
        sdk.callAction({
          actionName: 'furniturechoice-loqate/getAddressDetails',
          payload,
        }),
      );

      if (res.isError) {
        throw res.error;
      }

      const addressDetails = res.data as AddressDetailResponse[] | undefined;
      if (addressDetails && addressDetails.length > 0) {
        const firstDetail = addressDetails[0];
        setAddress({
          ...firstDetail,
          CustomItem: `${firstDetail.SubBuilding ? firstDetail.SubBuilding + ', ' : ''}${
            firstDetail.Company ? firstDetail.Company + ', ' : ''
          }${firstDetail.BuildingName ? firstDetail.BuildingName + ' ' : ''}${
            firstDetail.BuildingNumber ? firstDetail.BuildingNumber : ''
          }`.trim(),
        });
      } else {
        setAddress({
          Company: '',
          BuildingNumber: '',
          SubBuilding: '',
          BuildingName: '',
          Street: '',
          District: '',
          City: '',
          ProvinceName: '',
          PostalCode: '',
          CountryName: '',
          CountryIso2: '',
          CustomItem: '',
        });
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
    }
  };

  return {
    suggestedAddresses,
    address,
    error,
    getSuggestedAddresses,
    getAddress,
    setAddress: handleAddressFieldInput,
  };
}
