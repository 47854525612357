type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 60" xmlSpace="preserve">
    <path
      d="M34.65 12.49 9.09 56.77h61.82L40 3.23"
      style={{
        fill: 'none',
        stroke: '#faa376',
        strokeWidth: 4,
        strokeLinecap: 'square',
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M40 21.63v17.06m0 9.32v-5.07"
      style={{
        fill: 'none',
        stroke: '#faa376',
        strokeWidth: 4,
        strokeMiterlimit: 10,
      }}
    />
  </svg>
);

export default Icon;
