import { useCallback } from 'react';
import useSWR from 'swr';
import { sdk } from 'sdk';
import { revalidateOptions } from 'frontastic';

const useMailchimp = (type: 'newsletter' | 'press_centre') => {
  const result = useSWR(['furniturechoice-newsletter/getSubscriptionStatus', type], {
    ...revalidateOptions,
    revalidateOnMount: true,
  });

  const subscribe = useCallback(
    async (email?: string) => {
      const res = await sdk.callAction({
        actionName: 'furniturechoice-newsletter/add',
        payload: {
          type: type,
          email: email,
        },
      });

      return res.isError ? {} : res.data;
    },
    [type],
  );

  const unsubscribe = useCallback(async () => {
    const res = await sdk.callAction({
      actionName: 'furniturechoice-newsletter/remove',
      payload: {
        type: type,
      },
    });

    return res.isError ? {} : res.data;
  }, [type]);

  return {
    subscriptionStatus: result,
    subscribe,
    unsubscribe,
  };
};

export default useMailchimp;
