import { HTMLAttributes, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { MdClose } from 'react-icons/md';
import WarningIcon from '~/components/icons/warning';
import usePopupFocusEffect from '~/helpers/hooks/furniturechoice/use-popup-focus-effect';
import { useFormat } from '~/helpers/hooks/useFormat';

interface PopupModalProps extends HTMLAttributes<HTMLDialogElement> {
  isOpen: boolean;
  isModal?: boolean;
  title?: string;
  isManagingRootDocScroll?: boolean;
  onModalClose: () => void;
}

export default function PopupModal({
  className,
  children,
  isOpen,
  isModal = false,
  title,
  isManagingRootDocScroll = true,
  onModalClose,
  ...props
}: PopupModalProps): JSX.Element {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  usePopupFocusEffect({ isPopupVisible: isOpen, manageRootDocScroll: isManagingRootDocScroll });

  useEffect(() => {
    const dialogEl = dialogRef.current as unknown as HTMLDialogElement;

    if (isOpen) {
      if (isModal) {
        dialogEl.showModal();
      } else {
        dialogEl.show();
      }
    } else {
      dialogEl.close();
    }
  }, [isModal, isOpen]);

  return (
    <>
      <dialog
        ref={dialogRef}
        className={clsx(
          'fixed inset-y-0 z-600 mx-8 flex h-full min-h-screen w-[calc(100%_-_4rem)] bg-transparent backdrop:bg-backdrop md:w-full md:max-w-[574px] lg:mx-auto',
          className,
        )}
        inert={!isOpen ? '' : undefined}
        onClick={(event) => event.target === dialogRef.current && !isModal && onModalClose()}
        data-testid="popup-modal"
        {...props}
      >
        <div
          className={clsx('relative my-auto w-full flex-col overflow-y-auto rounded bg-white p-6', {
            flex: isOpen,
            hidden: !isOpen,
          })}
        >
          <button
            className="pointer-events-auto absolute right-0 top-0 cursor-pointer bg-white p-4 text-grey-3"
            onClick={onModalClose}
            aria-label={formatMessage({ id: 'components.side-modal.close' })}
          >
            <MdClose size={24} />
          </button>
          <div className="mb-4 flex items-start justify-between">
            <WarningIcon className="h-[48px] w-[68px]" />
          </div>
          {title && <div className="mb-6 flex items-center font-sans text-18 font-semibold leading-5">{title}</div>}

          <div className="flex-1 pb-2 leading-5 text-grey-5">{children}</div>
        </div>
      </dialog>

      {!isModal && isOpen && (
        <div className={clsx('fixed inset-0 z-[595] bg-backdrop')} hidden={!isOpen} onClick={onModalClose} />
      )}
    </>
  );
}
