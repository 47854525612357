import { UseCartReturn } from '~/frontastic/hooks/useCart/types';
import buildAddress from './build-address';

export default async function setCartAddresses(
  cart: UseCartReturn,
  payload: Record<string, FormDataEntryValue>,
): Promise<void> {
  const billing = buildAddress(payload, 'billing');
  const shipping = buildAddress(payload, 'shipping');
  const response = await cart.updateCart({
    account: { email: payload['billing.email'] as string },
    billing: { ...billing, email: payload['billing.email'] as string, isBillingAddress: true },
    shipping: { ...billing, ...shipping, email: payload['billing.email'] as string, isShippingAddress: true },
  });

  if (!response.cartId) {
    throw new Error('Unable to update cart billing and shipping address');
  }
}
