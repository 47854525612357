import { Address } from 'shared/types/account';

export default function buildAddress(data: Record<string, FormDataEntryValue>, type: 'billing' | 'shipping'): Address {
  const address: Record<string, FormDataEntryValue> = {};

  for (const key in data) {
    if (key.startsWith(`${type}.`)) {
      const cleanKey = key.split('.')[1];

      address[cleanKey] = data[key];
    }
  }

  return address;
}
