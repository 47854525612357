'use client';

import { Account } from 'shared/types/account';
import { Cart } from 'shared/types/cart';
import CheckoutOrderSummary from '~/components/furniturechoice/checkout-order-summary';
import { ImageProps } from '~/frontastic/lib/image';
import { TasticProps } from '~/frontastic/tastics/types';
import { DataSourceV2 } from '~/types/datasource';
import Form from './form';

interface InformationContentManagerProps {
  cartWithAccount: DataSourceV2<{ cart: Cart; account?: Account }>;
  freeDeliveryImage?: ImageProps;
  freeReturnsImage?: ImageProps;
  financeImage?: ImageProps;
}

export default function InformationContentManager({ data }: TasticProps<InformationContentManagerProps>) {
  const { cart, account } = data.cartWithAccount.dataSource;

  return (
    <div className="container mb-20 mt-12 grid grid-cols-1 gap-x-12 gap-y-10 lg:mt-10 lg:grid-cols-2">
      <CheckoutOrderSummary
        cart={cart}
        freeDeliveryImage={data.freeDeliveryImage}
        freeReturnsImage={data.freeReturnsImage}
        financeImage={data.financeImage}
      />
      <Form cart={cart} account={account} />
    </div>
  );
}
